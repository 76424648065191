import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "youre-well-on-your-way-to-build-something-great"
    }}>{`You're well on your way to build something great`}</h1>
    <p>{`You're the best ❤️`}</p>
    <p>{`Hope you enjoyed the Serverless Workshop. I'm excited to see what you build!`}</p>
    <p>{`We covered a lot today and it's okay if things feel shakey. `}<em parentName="p">{`Now`}</em>{` is the time to go out and use what you've learned on a project of your own.`}</p>
    <p>{`Before you go, could you answer a couple questions? Takes 5 minutes`}</p>
    <div className="typeform-widget" data-url="https://swizecteller.typeform.com/to/ODSImq5U" data-transparency="50" data-hide-headers="true" data-hide-footer="true" style={{
      "width": "100%",
      "height": "600px"
    }}></div>
    <p>{`No form? Hit refresh, or `}<a parentName="p" {...{
        "href": "https://swizecteller.typeform.com/to/ODSImq5U"
      }}>{`click here`}</a></p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      